<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- selection="multiple" -->
          <c-table
            ref="table"
            title="검진결과 목록"
            tableId="table"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :merge="grid.merge"
            :data="grid.data"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :isExcelDown="false"
            :isFullScreen="false"
            :expandAll="true"
            rowKey="heaCheckupResultId"
            @linkClick="linkClick"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="결과 엑셀업로드" icon="upload"  @btnClicked="openExcelUploader"/>
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'check-up-result',
  props: {
    data: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
        checkupTypeCd: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
        ],
        columns: [
        ],
        data: [],
        height: '800px',
      },
      mappingType: 'POST',
      listUrl: '',
      isSave: false, 
      saveUrl: transactionConfig.hea.checkup.results.save.url,
      deleteUrl: transactionConfig.hea.checkup.results.delete.url,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 300) + 'px';
    },
  },
  watch: {
    'count'() {
      this.setHeader();
      this.getDetail();
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.result.list.url;
      this.saveUrl = transactionConfig.hea.checkup.results.save.url;
      this.deleteUrl = transactionConfig.hea.checkup.results.delete.url;

      this.setHeader();
      this.getDetail();
    },
    setHeader() {
      if (this.data.checkupTypeCd === 'CUTC000001') {
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          // {
          //   name: 'checkTypeName',
          //   field: 'checkTypeName',
          //   disabled: true,
          //   label: '검진구분',
          //   align: 'center',
          //   style: 'width:100px',
          // },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자명',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'judgmentName',
            field: 'judgmentName',
            label: '판정',
            align: 'center',
            style: 'width: 50px',
            sortable: false,
          },
          {
            name: 'diseaseName',
            field: 'diseaseName',
            label: '질환',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
        ]
      } else if (this.data.checkupTypeCd === 'CUTC000002') { 
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          // {
          //   name: 'checkTypeName',
          //   field: 'checkTypeName',
          //   disabled: true,
          //   label: '검진구분',
          //   align: 'center',
          //   style: 'width:100px',
          // },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자명',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'judgmentName',
            field: 'judgmentName',
            label: '판정',
            align: 'center',
            style: 'width: 50px',
            sortable: false,
          },
          {
            name: 'diseaseName',
            field: 'diseaseName',
            label: '질환',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'hazardNames',
            field: 'hazardNames',
            label: '관련유해인자',
            align: 'left',
            style: 'width: 450px',
            sortable: false,
          },
        ]
      }
    },
    getDetail() {
      if (this.data.heaCheckupPlanId) {
        this.$http.url = this.$format(this.listUrl, this.data.heaCheckupPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      } 
    },
    linkClick(result) {
      this.popupOptions.target = () => import(`${"./checkUpResultDetail.vue"}`);
      this.popupOptions.title = result.userName + '의 건강검진 결과 상세';
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        heaCheckupResultId: result ? result.heaCheckupResultId : '',
        checkupTypeCd: this.data.checkupTypeCd,
        checkTypeName: result ? result.checkTypeName : '',
        checkupDate: result ? result.checkupDate : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    openExcelUploader() {
      this.popupOptions.title = '건강검진결과 업로드'; // 건강검진결과 업로드
      this.popupOptions.param = {
        checkupTypeCd: this.data.checkupTypeCd,
        plantCd: this.data.plantCd,
      }
      this.popupOptions.target = () => import(`${'./checkUpResultExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result, columns) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.heaCheckupPlanId = this.data.heaCheckupPlanId
          item.checkupTypeCd = this.data.checkupTypeCd
          item.heaCheckupResultId = uid();  // 일련번호
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
          item.editFlag = 'C';
          item.columnList = columns
        })
        this.$http.url = transactionConfig.hea.checkup.results.uploadSave.url;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
          this.$emit('getDetail')
        },);
      }

    }
  }
};
</script>