var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "검진결과 목록",
                    tableId: "table",
                    columns: _vm.grid.columns,
                    gridHeight: _vm.grid.height,
                    merge: _vm.grid.merge,
                    data: _vm.grid.data,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    isExcelDown: false,
                    isFullScreen: false,
                    expandAll: true,
                    rowKey: "heaCheckupResultId",
                  },
                  on: { linkClick: _vm.linkClick },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  label: "결과 엑셀업로드",
                                  icon: "upload",
                                },
                                on: { btnClicked: _vm.openExcelUploader },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }